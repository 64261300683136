export enum TokenType {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
}

export type Token = {
  id: number
  contract_address: string
  name: string
  symbol: string
  decimals: number
  image_url: string | null
  token_type: TokenType
  is_display: boolean
  is_native_token: boolean
  is_sendable: boolean
}

export type ContractAddress = string

export type ChainBaseTokenPriceResponse = {
  code: number
  data: ChainBaseTokenPrice
  message: string
}

export type ChainBaseTokenPrice = {
  decimals: number
  price: number
  symbol: string
  updated_at: string
}

export type TokenPriceBinanceResponse = {
  symbol: string
  priceChange: string
  priceChangePercent: string
  weightedAvgPrice: string
  prevClosePrice: string
  lastPrice: string
  lastQty: string
  bidPrice: string
  bidQty: string
  askPrice: string
  askQty: string
  openPrice: string
  highPrice: string
  lowPrice: string
  volume: string
  quoteVolume: string
  openTime: number
  closeTime: number
  firstId: number
  lastId: number
  count: number
}
