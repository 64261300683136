import BearGif from '@/assets/gifs/bear.gif'
import PlaySvg from '@/assets/svg/play-ic.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { useMyPoint } from '@/libs/hooks/usePoint'
import { Tabs } from '@/libs/types'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { bottomTabStore } from '@/store/BottomTabStore'
import type { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import type React from 'react'
import { Text } from 'react-aria-components'
import { WalletButton } from '../Button/WalletButton'

type PropType = {
  slides: number[]
  options?: EmblaOptionsType
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props
  const [emblaRef] = useEmblaCarousel(options)
  const point = useMyPoint()

  const redirectToGame = () => {
    handleReactGASendEvent('PlayGameButtonClick')

    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Game,
    })
  }

  return (
    <section className="embla" id="embla-carousel">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <div className=" flex flex-col bg-yellow-800 w-full h-[80%] rounded-2xl px-4 justify-center gradient_border_refill ">
                  <div className="flex flex-col">
                    <Text className="text-rgba font-bold text-xl">
                      Bear Hunting
                    </Text>
                    <Text className="text-rgba font-normal text-xs max-w-[60%]">
                      Guess BTC price and earn $BECA now!
                    </Text>
                  </div>
                  <div className="flex mt-1 items-center">
                    <img src={BeraCoinImg} alt="point" className="w-6 h-6" />
                    <Text className="text-rgba font-bold text-xl ml-2">
                      {point.toLocaleString('en-US')}
                    </Text>
                  </div>
                </div>
                <img
                  src={BearGif}
                  alt="bera"
                  className="absolute top-3 right-[14px] w-[146px]"
                />
                <WalletButton
                  className="absolute bottom-3 right-[14px] text-[#5C4900] h-8 rounded-xl text-xs shadow-sm"
                  color="white"
                  leftIcon={
                    <img src={PlaySvg} alt="play" className="w-4 h-4" />
                  }
                  onPress={redirectToGame}
                >
                  Play Game
                </WalletButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export { EmblaCarousel }
