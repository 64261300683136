const contractAddressList = {
  CrocQuery: '0x8685CE9Db06D40CBa73e3d09e6868FE476B5dC89' as `0x${string}`,
  MultiSwap: '0x21e2C0AFd058A89FCf7caf3aEA3cB84Ae977B73D' as `0x${string}`,
  BerachainRewardsVault:
    '0xe5519D97eA854291c35a494b28929fA7abEf12e8' as `0x${string}`,
  CrocSwapDex: '0xAB827b1Cc3535A9e549EE387A6E9C3F02F481B49' as `0x${string}`,
  CBTRouter: '0x9DcF0eFb2F5C8A18A737bd14504612320A6c26bd' as `0x${string}`,
} as const

export { contractAddressList }
