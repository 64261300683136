export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format

export const addCommasToNumber = (value: string | number) => {
  if (value === '' || Number.isNaN(Number(value))) {
    return ''
  }
  const parts = value.toString().split('.')
  const integerPart = Number.parseInt(parts[0]).toLocaleString('en-US')
  // biome-ignore lint/style/useTemplate: <explanation>
  const decimalPart = parts.length > 1 ? '.' + parts[1] : ''
  return integerPart + decimalPart
}

export const formatNumber = (value: number | string, fractionDigits = 2) => {
  const parsedValue = Number.parseFloat(value.toString())

  return parsedValue.toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}
