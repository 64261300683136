
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import {
  useAllowance,
  useEstimateGasSwap,
  usePreviewSwap,
  useSwapTokens,
  useTokenBalance,
} from '@/libs/hooks'
import type { Token } from '@/libs/types'
import { contractAddressList } from '@/libs/utils/contractAddress'
import { isNativeToken } from '@/libs/utils/wallet/action'
import {
  getTransactionData,
  simulateApproveToken,
  simulateMultiSwap,
} from '@/libs/utils/wallet/swap'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useEffect, useMemo, useState } from 'react'
import { Input } from 'react-aria-components'
import { NumericFormat } from 'react-number-format'
import { toast } from 'sonner'
import { isAddressEqual } from 'viem'
import { privateKeyToAccount } from 'viem/accounts'
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { SettingModal } from './SettingModal'
import { SwapAndPoolTab } from './SwapAndPoolTab'

interface Props {
  activeTab: number,
  handleChangeActiveTab:(value: number) => void
}

const SwapPage = (props: Props) => {
  const { tokens } = useSwapTokens()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const queryClient = useQueryClient()
  const [sendToken, setSendToken] = useState<Token | undefined>(tokens[0])
  const [receiveToken, setReceiveToken] = useState<Token | undefined>(tokens[1])
  const [fromAmount, setFromAmount] = useState<string | number>(0)
  const [slippage] = useState<number>(1)
  const [openModalToken, setOpenModalToken] = useState<boolean>(false)
  const [openModalSetting, setOpenModalSetting] = useState<boolean>(false)
  const [typeTokenSelect, setTypeTokenSelect] = useState<'send' | 'receive'>(
    'send',
  )

  const [slippageValue, setSlippageValue] = useState<string>('1')
  const [slippageType, setSlippageType] = useState<number>(1)

  const handleChangeSlippageType = (value: number) => {
    if(value === 1) {
      setSlippageValue('0.5')
    }
    if(value === 2) {
      setSlippageValue('1')
    }
    if(value === 3) {
      setSlippageValue('2')
    }
    setSlippageType(value)
  }

  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const { data, isLoading: isLoadingPreviewSwap } = usePreviewSwap(
    sendToken!,
    receiveToken!,
    Number(fromAmount),
  )
  const { balance } = useTokenBalance(signer.address!, sendToken!)
  const { fee } = useEstimateGasSwap(
    sendToken!,
    receiveToken!,
    Number(fromAmount),
    Number(data?.quantity),
    slippage,
    signer,
  )

  const { allowance } = useAllowance(
    signer.address,
    sendToken!,
    contractAddressList.MultiSwap,
  )

  const {
    data: hash,
    isPending: isSendingTx,
    error: errSendTx,
    writeContract,
  } = useWriteContract()

  const { data: transactionReceipt, isFetching: isFetchingTxReceipt } =
    useWaitForTransactionReceipt({
      hash,
      confirmations: 1,
    })

  useEffect(() => {
    if (tokens?.length > 0) {
      setSendToken(tokens[0])
      setReceiveToken(tokens[1])
    }
  }, [tokens])

  useEffect(() => {
    if (errSendTx) {
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    }
  }, [errSendTx])

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    // remove commas
    value = value.replace(/,/g, '')
    // Only allow digits and at most one dot
    value = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1')
    // If the value starts with a dot, prepend a zero
    if (value.startsWith('.')) {
      value = `0${value}`
    }
    setFromAmount(value)
  }

  const receiveAmount = useMemo(() => {
    return data?.quantity && Number(fromAmount) > 0 ? data.quantity : 0
  }, [data?.quantity, fromAmount])

  const swapToken = () => {
    const temp = { ...sendToken }
    const _receivedAmount = receiveAmount
    setSendToken(receiveToken)
    setReceiveToken(temp as Token)
    setFromAmount(_receivedAmount)
  }

  const changeTokenSend = () => {
    setTypeTokenSelect('send')
    setOpenModalToken(true)
  }

  const changeTokenReceive = () => {
    setTypeTokenSelect('receive')
    setOpenModalToken(true)
  }

  const chooseToken = (token: Token) => {
    if (typeTokenSelect === 'send') {
      setSendToken(token)
    }
    if (typeTokenSelect === 'receive') {
      setReceiveToken(token)
    }
    setOpenModalToken(false)
  }

  const approveToken = async () => {
    const request = await simulateApproveToken({
      token: sendToken as Token,
      spender: contractAddressList.MultiSwap,
    })
    writeContract({ ...request, account: signer })
  }

  const handleSwap = async () => {
    const minOut =
      Number(receiveAmount) - (slippage / 100) * Number(receiveAmount)
    const request = await simulateMultiSwap({
      mode: 'multiSwap',
      from: sendToken as Token,
      to: receiveToken as Token,
      amount: Number(fromAmount),
      minOut,
      signer,
    })
    writeContract({ ...request, account: signer })
  }

  const handleClickBtn = async () => {
    if (Number(fromAmount) > Number(allowance) && !isNativeToken(sendToken!)) {
      await approveToken()
    } else {
      await handleSwap()
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (transactionReceipt) {
      setFromAmount(0)
      toast.success('Swap successfully!')
      getTransactionData(transactionReceipt.transactionHash)
      queryClient.invalidateQueries({ queryKey: ['allowance'] })
      queryClient.invalidateQueries({
        queryKey: ['tokenBalance', sendToken?.contract_address, signer.address],
      })
      queryClient.invalidateQueries({
        queryKey: ['swap-history', signer.address],
      })
    }
  }, [transactionReceipt])

  const disableBtnSwap =
    Number(fromAmount) > Number(balance) ||
    isSendingTx ||
    isFetchingTxReceipt ||
    Number(fromAmount) === 0 ||
    Number(receiveAmount) === 0

  const listSelectToken = useMemo(() => {
    if (tokens?.length > 0 && sendToken && receiveToken) {
      return tokens.filter(
        (token) =>
          !isAddressEqual(
            token.contract_address as `0x${string}`,
            sendToken?.contract_address as `0x${string}`,
          ) &&
          !isAddressEqual(
            token.contract_address as `0x${string}`,
            receiveToken?.contract_address as `0x${string}`,
          ),
      )
    }
    return []
  }, [tokens, sendToken, receiveToken])

  return (
    <div className="p-[15px] flex flex-col py-8 bg-[#52A7E733] gap-[5px] overflow-auto h-[calc(100vh)] ">
      <p className='text-[#ffffff] text-[24px] font-bold'>BEX</p>
      <p className='text-[#ffffffa0] text-[14px] font-[400] mb-[10px]'>Swap tokens and provide liquidity</p>
      <div className="flex h-fit flex-col bg-background text-foreground w-full rounded-2xl px-2 pb-8 pt-4 bg-[#52A7E73D] shadow-lg">
        <div className='flex items-center justify-between px-2'>
          <h3 className="text-lg font-bold leading-none tracking-tight center flex items-center justify-start">
            Swap
          </h3>
          <div onClick={() => setOpenModalSetting(true)} onKeyDown={() => {}}>
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings">
              <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
          </div>
        </div>
        <div className='w-full my-[10px]'>
          <SwapAndPoolTab activeTab={props.activeTab} handleChangeActiveTab={props.handleChangeActiveTab} />
        </div>
        <div className="mt-3">
          <div className="border-1 flex flex-col gap-4 border-border">
            <ul className="divide-y divide-border rounded-2xl border-[#3892CF] border-[1px]">
              <li className="flex flex-col flex-wrap px-3 py-4">
                <div className="flex flex-row ">
                  <div className="flex flex-row items-center gap-1">
                    <div className="w-fit max-w-[150px]">
                      <p className='text-[#ffffffa0] text-[12px] font-[400]'>From</p>
                      {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                      <button
                        className="items-center justify-center transition-duration-300 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-30 disabled:pointer-events-none ring-offset-background border-[#e5e7eb80] border-[1px] hover:bg-secondary rounded-md text-lg font-semibold leading-7 flex h-10 w-full shrink-0 gap-1 bg-background p-2 text-secondary-foreground shadow"
                        onClick={() => changeTokenSend()}
                      >
                        <span className="relative shrink-0 overflow-hidden aspect-square flex items-center justify-center rounded-full text-foreground w-6 h-6 text-[8px]">
                          <img
                            className="aspect-square h-full w-full rounded-full"
                            alt="receive token"
                            src={sendToken?.image_url as string}
                          />
                        </span>
                        <span className="w-fit max-w-[140px] overflow-hidden truncate">
                          {sendToken?.name}
                        </span>
                        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-4 w-4"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="ml-2 flex w-full flex-col pl-2 sm:pl-0">
                    <p className="text-[12px] mt-1 flex justify-end items-center gap-[5px]">
                      <span className="mt-[-2px]">
                        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-3 w-3 text-muted-foreground"
                        >
                          <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
                          <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
                          <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
                        </svg>
                      </span>{' '}
                      {balance}
                    </p>
                    <div className="relative w-full">
                      <NumericFormat
                        autoFocus
                        allowedDecimalSeparators={['.', ',']}
                        thousandSeparator=","
                        allowNegative={false}
                        allowLeadingZeros={false}
                        value={fromAmount}
                        onChange={handleChangeAmount}
                        inputMode="decimal"
                        placeholder="0"
                        className="focus:border-1 flex h-10 rounded-md border-border text-foreground file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-foreground focus:outline-none disabled:cursor-not-allowed disabled:text-muted-foreground ring-offset-none w-full grow border-0 bg-transparent p-0 text-right text-lg font-semibold shadow-none outline-none ring-0 drop-shadow-none focus-visible:ring-0 focus-visible:ring-offset-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="h-fit w-full cursor-default">
                  <div className="flex w-full flex-row-reverse items-center justify-between gap-1">
                    <div className="flex flex-row gap-1">
                      <div className="flex flex-row gap-1 self-center p-0 text-xs text-muted-foreground" />
                    </div>
                  </div>
                </div>
              </li>
              <div className="relative border-[#3892CF]">
                <div
                  className="absolute inset-0 flex w-full items-center justify-center"
                  aria-hidden="true"
                >
                  <button
                    className="justify-center transition-duration-300 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-30 disabled:pointer-events-none ring-offset-background border-[#3892CF] border-[1px] hover:bg-secondary z-10 inline-flex h-8 w-8 items-center rounded-[50%] bg-background p-0.5 text-sm font-semibold text-muted-foreground md:h-8 md:w-8 md:p-1 bg-[#3892CF]"
                    onClick={() => swapToken()}
                    type="button"
                  >
                    {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-4 w-4 md:h-6 md:w-6 text-[#ffffff]"
                    >
                      <path d="m2 9 3-3 3 3" />
                      <path d="M13 18H7a2 2 0 0 1-2-2V6" />
                      <path d="m22 15-3 3-3-3" />
                      <path d="M11 6h6a2 2 0 0 1 2 2v10" />
                    </svg>
                  </button>
                </div>
              </div>
              <li className="flex flex-col flex-wrap px-3 py-4 border-[#3892CF]">
                <div className="flex flex-row items-end">
                  <div className="flex flex-row items-center gap-1">
                    <div className="w-fit max-w-[150px]">
                    <p className='text-[#ffffffa0] text-[12px] font-[400]'>To</p>
                      {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                      <button
                        className="items-center justify-center transition-duration-300 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-30 disabled:pointer-events-none ring-offset-background border-[#e5e7eb80] border-[1px] hover:bg-secondary rounded-md text-lg font-semibold leading-7 flex h-10 w-full shrink-0 gap-1 bg-background p-2 text-secondary-foreground shadow"
                        onClick={() => changeTokenReceive()}
                      >
                        <span className="relative shrink-0 overflow-hidden aspect-square flex items-center justify-center rounded-full text-foreground w-6 h-6 text-[8px]">
                          <img
                            className="aspect-square h-full w-full rounded-full"
                            alt="receive token"
                            src={receiveToken?.image_url as string}
                          />
                        </span>
                        <span className="w-fit max-w-[140px] overflow-hidden truncate">
                          {receiveToken?.name}
                        </span>
                        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-4 w-4"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="ml-2 flex w-full flex-col pl-2 sm:pl-0">
                    <div className="relative w-full">
                      {!isLoadingPreviewSwap && (
                        <Input
                          className="focus:border-1 flex h-10 rounded-md border-border text-foreground file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-foreground focus:outline-none disabled:cursor-not-allowed disabled:text-muted-foreground disabled:opacity-100 ring-offset-none w-full grow border-0 bg-transparent p-0 text-right text-lg font-semibold shadow-none outline-none ring-0 drop-shadow-none focus-visible:ring-0 focus-visible:ring-offset-0"
                          disabled
                          value={receiveAmount.toLocaleString('en-US')}
                        />
                      )}
                      {isLoadingPreviewSwap && (
                        <div className="flex items-center justify-end">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="h-fit w-full cursor-default">
                  <div className="flex w-full flex-row-reverse items-center justify-between gap-1">
                    <div className="flex flex-row gap-1">
                      <div className="flex flex-row gap-1 self-center p-0 text-xs text-muted-foreground" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="flex flex-col gap-2">
              <div className="flex w-full flex-col gap-[10px] rounded-[16px] bg-[#0C1E2B] p-[20px]">
                <div className="flex w-full flex-row justify-between">
                  <p className="text-xs font-medium text-muted-foreground sm:text-sm text-[#ffffffa0]">
                    Exchange rate
                  </p>
                  {data && data?.rate > 0 && Number(fromAmount) > 0 ? (
                    <p className="whitespace-nowrap text-right text-xs font-medium sm:text-sm">
                      {`1 ${sendToken?.name} = ${Number(data?.rate).toFixed(6)} ${receiveToken?.name}`}
                    </p>
                  ) : (
                    <p className="whitespace-nowrap text-right text-xs font-medium sm:text-sm">
                      -
                    </p>
                  )}
                </div>
                <div className="flex w-full flex-row items-center justify-between h-[20px]">
                  <p className="text-xs font-medium text-muted-foreground sm:text-sm text-[#ffffffa0]">
                    Network fee
                  </p>
                  {fee ? (
                    <p className="cursor-help whitespace-nowrap text-right text-xs font-medium sm:text-sm">
                      <span className="flex flex-row items-center justify-center gap-1">
                        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-4 w-4"
                        >
                          <line x1="3" x2="15" y1="22" y2="22" />
                          <line x1="4" x2="14" y1="9" y2="9" />
                          <path d="M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18" />
                          <path d="M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2h0a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5" />
                        </svg>
                        <span className="pl-[5px] pt-[2px]">{`=$${fee?.toFixed(8)} BERA`}</span>
                      </span>
                    </p>
                  ): (
                    <p className="whitespace-nowrap text-right text-xs font-medium sm:text-sm">
                      -
                    </p>
                  )}
                </div>
              </div>
            </div>
            {Number(fromAmount) > Number(balance) && (
              <div className="px-2">
                <p className="text-sm text-rose-600 mt-2 leading-[1.3]">
                  You don't have enough {sendToken?.symbol}
                </p>
              </div>
            )}
            <div className="w-full">
              <div className="">
                {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                <button
                  className={`${Number(fromAmount) > 0 ? "border-[#FAC808] bg-[#8C6F00]" : "border-[#FAC80880] bg-[#8C6F0080]"} button button--padding-large button--primary connect-button w-full border-[1.5px] rounded-[12px] disabled:cursor-not-allowed`}
                  onClick={handleClickBtn}
                  disabled={disableBtnSwap}
                >
                  <span className="typography typography--button-primary  typography--primary  ">
                    <div className="inline-flex h-fit items-center justify-center transition-duration-300 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-30 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:opacity-90 px-4 py-2 rounded-md text-lg leading-7 gap-2 font-semibold w-full">
                      {Number(fromAmount) > Number(allowance) &&
                      !isNativeToken(sendToken!)
                        ? 'Approve'
                        : 'Swap'}
                      {(isSendingTx || isFetchingTxReceipt) && (
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      )}
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomSheetModal
        open={openModalToken}
        title={'Select a token'}
        onClose={() => setOpenModalToken(false)}
        heightPercent={0.7}
      >
        <div className="flex flex-col w-full max-h-[calc(100vh-200px)] overflow-auto p-[20px]">
          {listSelectToken.map((element: Token) => {
            return (
              <div
                key={element.name}
                onClick={() => chooseToken(element)}
                onKeyDown={() => {}}
                className="w-full flex gap-[20px] items-center justify-start hover:bg-[#e5e7eb80] hover:rounded-[12px] p-[10px] border-b-[1px] border-[#3892CF]"
              >
                <img
                  src={element.image_url as string}
                  alt="token avatar"
                  className="h-[30px] w-[30px]"
                />
                <p className="font-bold text-[16px] text-[#ffffff80]">
                  {element.name}
                </p>
              </div>
            )
          })}
        </div>
      </BottomSheetModal>
      <SettingModal 
        open={openModalSetting} 
        setOpen={setOpenModalSetting} 
        slippageValue={slippageValue}
        slippageType={slippageType}
        handleChangeSlippageType={handleChangeSlippageType}
        setSlippageValue={setSlippageValue}
      />
    </div>
  )
}

export { SwapPage }
