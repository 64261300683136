import { useMutation } from '@tanstack/react-query'
import request from '../config/request'
import type { ICreateMemeToken } from '../types'
import { getMemePredictedTokenAddress } from '../utils/wallet/meme'

async function createMemeToken(data: ICreateMemeToken) {
  const tokenAddress = await getMemePredictedTokenAddress({
    ownerAddress: data.owner_address,
    name: data.name,
    symbol: data.symbol,
  })

  data.address = tokenAddress as string

  //check if key has value then append to form data

  const formData = new FormData()
  for (const key of Object.keys(data)) {
    const value = data[key as keyof ICreateMemeToken]
    if (value !== undefined && value !== '') {
      formData.append(key, value as string | Blob)
    }
  }

  const res = await request.post('/meme-coin', formData)
  const tokenId: number = Number(res?.data?.insertId)
  return tokenId
}

export const useMutationCreateMemeToken = () => {
  return useMutation({
    mutationFn: (data: ICreateMemeToken) => createMemeToken(data),
  })
}
