import { Button, type ButtonProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'

type Color = 'yellow' | 'green' | 'blue' | 'white' | 'blue-800' | 'not-shadow'
export interface WalletButtonProps extends ButtonProps {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  children?: React.ReactNode
  className?: string
  color?: Color
}

const defaultBtnStyle = `overflow-hidden
                flex flex-1 items-center justify-center rounded-2xl
                bg-yellow-300 bg-clip-padding p-2
                disabled:opacity-12
                text-white text-sm font-bold hover:bg-opacity-50 pressed:bg-opacity-70 transition-colors 
                cursor-default outline-none h-18 gap-1 border-[1.5px]`

const colorMap: Record<Color, string> = {
  yellow: 'border-yellow-400 shadow-300',
  green: 'border-green-800 shadow-200 bg-green',
  blue: 'bg-blue-300 border-blue-400 shadow-100',
  white: ' bg-white border-yellow-300 shadow-400',
  'blue-800': 'bg-[#0C1E2B] border-[#003F60] shadow-500',
  'not-shadow': 'bg-[#0C1E2B] border-[#003F60]'
}

const WalletButton: React.FC<WalletButtonProps> = ({
  children,
  leftIcon,
  rightIcon,
  className,
  color = 'yellow',
  ...props
}) => {
  return (
    <Button
      {...props}
      className={twMerge(defaultBtnStyle, className, colorMap[color])}
    >
      {leftIcon && <span>{leftIcon}</span>}
      {children}
      {rightIcon && <span>{rightIcon}</span>}
    </Button>
  )
}

export { WalletButton }
