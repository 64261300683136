import type { TaskInfo } from "@/libs/types/task"
import { generateImageByTask } from "@/libs/utils/task/task";
import { useMemo } from "react"
import { Card } from "./Card";

interface Props {
  tasks: TaskInfo[]
  handleChangeTask: (id: number) => void
  handleClaimTask: (id: number) => void
}

export function StandardTask (props: Props) {
  const calculatingTaskDaily = useMemo(() => {
    let result = 0;
      props.tasks.map((element) => {
        if(element.task.frequency_type === 'daily' && element.task.task_type !== 'partner') {
          result = result + 1
        }
      })
    return result
  }, [props.tasks])

  const calculatingTaskSystem = useMemo(() => {
    let result = 0;
      props.tasks.map((element) => {
        if(element.task.frequency_type !== 'daily' && element.task.task_type !== 'partner') {
          result = result + 1
        }
      })
    return result
  }, [props.tasks])
  return (
    <div className="w-full max-h-[calc(100vh-280px)] overflow-auto">
      {calculatingTaskDaily > 0 && <p className="text-[#52A7E7] text-[18px] font-bold mb-[10px] font-['DM Sans']">Daily</p>}
      {calculatingTaskDaily > 0 && (
        <div className="border-[1.5px] border-[#3892CF] rounded-3xl bg-[#52A7E73D] flex flex-col gap-[10px] p-[10px] mb-[20px]">
        {props.tasks.map((element, index: number) => {
          return element.task.task_type !== 'partner' && element.task.frequency_type === 'daily' && (
            <Card
              key={element.task.id}
              avatar={
               element?.task?.image_url ? element?.task?.image_url : generateImageByTask(element)
              }
              name={element.task.title}
              value={`+${element.task.reward_point.toLocaleString('en-US')}`}
              unit="$BECA"
              status={
                element.task.statusTask as
                  | 'GO'
                  | 'CLAIM'
                  | 'LOADING'
                  | 'SUCCESS'
              }
              handleChangeTask={props.handleChangeTask}
              index={index}
              task={element}
              id={element.task.id}
              handleClaimTask={props.handleClaimTask}
            />
          )
        })}
      </div>
      )}
      {calculatingTaskSystem > 0 && <p className="text-[#52A7E7] text-[18px] font-bold mb-[10px] font-[DM Sans]">Basic Tasks</p>}
      {calculatingTaskSystem > 0 && (
        <div className="border-[1.5px] border-[#3892CF] rounded-3xl bg-[#52A7E73D] flex flex-col gap-[10px] p-[10px] ">
        {props.tasks.map((element, index: number) => {
          return element.task.task_type !== 'partner' && element.task.frequency_type !== 'daily' && (
            <Card
              key={element.task.id}
              avatar={
               element?.task?.image_url ? element?.task?.image_url : generateImageByTask(element)
              }
              name={element.task.title}
              value={`+${element.task.reward_point.toLocaleString('en-US')}`}
              unit="$BECA"
              status={
                element.task.statusTask as
                  | 'GO'
                  | 'CLAIM'
                  | 'LOADING'
                  | 'SUCCESS'
              }
              handleChangeTask={props.handleChangeTask}
              index={index}
              task={element}
              id={element.task.id}
              handleClaimTask={props.handleClaimTask}
            />
          )
        })}
      </div>
      )}
    </div>
  )
}