interface Props {
  tab: number,
  handleChangeTab:(value: number) =>  void
}

export function ListTab (props: Props) {
  const SidebarOption = [
     'All', 'Trending', 'NewList', 'Favorite'
  ]
  return (<div className="w-[calc(100%-20px)] h-[44px] flex p-[2px] mx-[10px] mt-[20px] mb-[0px] pb-[0px] border-b-[#003F60] border-b-[1px] border-solid">
      {SidebarOption.map((element: string, index: number) => {
          return (
              <div key={element} onClick={() => props.handleChangeTab(index)} onKeyDown={() => {}} className="w-1/4 flex justify-center items-end" >
                  <p className={`text-sm font-bold ${index === props.tab ? "text-[#FAC808] border-b-[#FAC808]" : "text-[#ffffff] border-b-[transparent]"} border-solid border-b-[2px] uppercase leading-[2]`}>{element}</p>
              </div>
          )
      })}
  </div>)
}