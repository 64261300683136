import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Pagination } from '../types'

async function getMemeTokens({ page = 1, pageSize = 10,searchTerm }: Pagination &{
  searchTerm?:string
}) {
  const res = await request.get('meme-coins', {
    params: {
      page,
      pageSize,
      searchTerm
    },
  })
  return res.data
}

export const useMemeTokens = ({ page = 1, pageSize = 10,searchTerm }: Pagination & {
  searchTerm?:string
}) => {
  const { data, ...res } = useQuery({
    queryKey: ['meme-tokens', page, pageSize,searchTerm],
    queryFn: () => getMemeTokens({ page, pageSize, searchTerm }),
    refetchOnWindowFocus: true
  })

  return { tokens: data?.data ?? [], pagination: data?.pagination, ...res }
}
