import { useQuery } from '@tanstack/react-query'
import { checkSendToken} from '../utils/wallet/action'

export function useCheckSendToken(
  walletAddress: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['check-send-token', walletAddress],
    queryFn: () => checkSendToken(walletAddress),
    enabled: !!walletAddress,
  })

  return { isSendToken: data ?? false, ...res }
}
