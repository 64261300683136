import { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs as TabsView } from 'react-aria-components'
import SwipeableViews from 'react-swipeable-views'
import { NFTAssets } from '../NFTAssets'
import { TokenAssets } from '../TokenAssets'
import { WalletFeature } from '../WalletFeature'
import '@/styles/aria-tabs.css'
import { EmblaCarousel } from '@/components/Carousel'
import {
  handleReactGASend,
  handleReactGASendEvent,
} from '@/libs/utils/tracking'
import { } from '@tanstack/react-query'

enum Assets {
  Token = 'token',
  NFT = 'nft',
}

enum AssetsIndex {
  Token = 0,
  NFT = 1,
}

const SLIDE_COUNT = 2
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

const TabWallet: React.FC = () => {
 
  const [swipeIndex, setSwipeIndex] = useState(AssetsIndex.Token)
  const onTabChange = (key: Assets) => {
    if (key === Assets.Token) {
      handleReactGASendEvent('TokenListClick')
      setSwipeIndex(AssetsIndex.Token)
    } else {
      handleReactGASendEvent('NftListClick')
      setSwipeIndex(AssetsIndex.NFT)
    }
  }

  const tabSelectedKey =
    swipeIndex === AssetsIndex.Token ? Assets.Token : Assets.NFT

  useEffect(() => {
    handleReactGASend('MainScreenHomeView')
  }, [])


  return (
    <div className="flex flex-col">
      <WalletFeature />
      <EmblaCarousel slides={SLIDES} />

      <div className="px-4 mt-6">
        <TabsView
          onSelectionChange={(key) => onTabChange(key as Assets)}
          selectedKey={tabSelectedKey}
        >
          <TabList aria-label="Wallet">
            <Tab id={Assets.Token}>Tokens</Tab>
            <Tab id={Assets.NFT}>NFTs</Tab>
          </TabList>

          <SwipeableViews index={swipeIndex} onChangeIndex={setSwipeIndex}>
            <TabPanel
              shouldForceMount
              id={Assets.Token}
              className="overflow-hidden"
            >
              <TokenAssets />
            </TabPanel>

            <TabPanel shouldForceMount id={Assets.NFT}>
              <NFTAssets />
            </TabPanel>
          </SwipeableViews>
        </TabsView>
      </div>
    </div>
  )
}

export { TabWallet }
