import BeraLogo from '@/assets/imgs/bera-logo.png'
import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { useDisabledBackButtonOnThisPage } from '@/libs/hooks'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'

const OnBoardPage: React.FC = () => {
  const navigate = useNavigate()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)

  if (!activeAccount) {
    useDisabledBackButtonOnThisPage()
  }
  return (
    <Container className="p-0">
      <Content className="pt-0">
        <div className="px-4 flex flex-[0.8] flex-col gap-4 bg-blue rounded-es-3xl rounded-ee-3xl">
          <div className="mt-10 flex flex-col items-center gap-6">
            <img src={BeraLogo} alt="abc" className="w-[120px]" />
            <div className="flex flex-col justify-center items-center">
              <Text className="font-semibold text-[32px]">Bear Cage</Text>
              <Text className="font-normal text-[16px]">
                The first super wallet on Telegram for Berachain
              </Text>
              <Text className="font-normal text-[16px]">Just BULL No Bear</Text>
            </div>
          </div>
        </div>
      </Content>
      <div className="flex flex-col mb-20 w-full gap-5 pb-5 px-4">
        <WalletButton
          className="w-full"
          onPress={() => {
            handleReactGASendEvent('CreateWalletButtonClick')
            navigate('/new-wallet')
          }}
        >
          Create new account
        </WalletButton>
        {activeAccount && (
          <WalletButton
            className="w-full"
            color="blue-800"
            onPress={() => {
              handleReactGASendEvent('ImportWalletButtonClick')
              navigate('/import-wallet')
            }}
          >
            Import account
          </WalletButton>
        )}
      </div>
    </Container>
  )
}

export { OnBoardPage }
