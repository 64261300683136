import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PoolPage } from './PoolPage'
import { SwapPage } from './SwapPage'

export enum SwapTab {
  Swap = 0,
  AddLiquidity = 1,
}

const SwapAndPool = () => {
  const [searchParams] = useSearchParams()
  const _activeTab = searchParams.get('activeTab')

  const [activeTab, setActiveTab] = useState<number>(
    _activeTab ? Number(_activeTab) : SwapTab.Swap,
  )

  const handleChangeActiveTab = (value: number) => {
    setActiveTab(value)
  }
  return (
    <div>
      {activeTab === SwapTab.Swap && <SwapPage activeTab={activeTab} handleChangeActiveTab={handleChangeActiveTab} />}
      {activeTab === SwapTab.AddLiquidity && <PoolPage activeTab={activeTab} handleChangeActiveTab={handleChangeActiveTab} />}
    </div>
  )
}

export { SwapAndPool }
