import type {
  AddLiquidityTransactionInfo,
  SwapTransactionInfo,
} from '@/libs/types'
import { createStore } from '@xstate/store'

const transactionStore = createStore(
  {
    txsSwap: [] as SwapTransactionInfo[],
    txsAddLiquidity: [] as AddLiquidityTransactionInfo[],
  },
  {
    initData: (
      _,
      {
        txsSwapData,
        txsAddLiquidityData,
      }: {
        txsSwapData: SwapTransactionInfo[]
        txsAddLiquidityData: AddLiquidityTransactionInfo[]
      },
    ) => ({
      txsSwap: txsSwapData,
      txsAddLiquidity: txsAddLiquidityData,
    }),

    addSwapTransaction: (
      context,
      { txData }: { txData: SwapTransactionInfo },
    ) => {
      const isExist = context.txsSwap.some((tx) => tx.hash === txData.hash)
      if (isExist) {
        return {
          txsSwap: [...context.txsSwap],
        }
      }
      return {
        txsSwap: [...context.txsSwap, txData],
      }
    },
    addAddLiquidityTransaction: (
      context,
      { txData }: { txData: AddLiquidityTransactionInfo },
    ) => {
      const isExist = context.txsAddLiquidity.some(
        (tx) => tx.hash === txData.hash,
      )
      if (isExist) {
        return {
          txsAddLiquidity: [...context.txsAddLiquidity],
        }
      }

      return {
        txsAddLiquidity: [...context.txsAddLiquidity, txData],
      }
    },
    clearTransaction: () => ({
      txsSwap: [],
      txsAddLiquidity: [],
    }),
  },
)

export { transactionStore }
