import { useQuery } from '@tanstack/react-query'
import { checkMintedNft} from '../utils/wallet/action'

export function useCheckMintedNft(
  walletAddress: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['check-minted-nft', walletAddress],
    queryFn: () => checkMintedNft(walletAddress),
    enabled: !!walletAddress,
  })

  return { isMintedNft: data ?? false, ...res }
}
