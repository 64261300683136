import TransactionSuccess from '@/assets/svg/transaction-success.svg'
import WarningIcon from '@/assets/svg/warning-icon.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import type { TaskInfo } from '@/libs/types/task'
import { generateImageByTask } from '@/libs/utils/task/task'
import {
  checkAddLiquidityToday,
  checkSwapTokenToday,
  claimTask,
  completeTask,
} from '@/libs/utils/wallet/action'
import { transactionStore } from '@/store/TransactionStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useMemo, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { LoadingButton } from './components/LoadingButton'

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  task: TaskInfo
}

export enum SwapTab {
  Swap = 0,
  AddLiquidity = 1,
}

export const TaskSwap: React.FC<Props> = ({ open, setOpen, task }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { txsSwap: swapHistory, txsAddLiquidity: addLiquidityTransaction } =
    useSelector(transactionStore, (state) => state.context)
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false)
  const [errorFaucetOpen, setErrorFaucetOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const completeMutation = useMutation({
    mutationFn: completeTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
      setSuccessDialogOpen(true)
    },
    onError: (error) => {
      toast.error(`Unable to complete task: ${error.message}`)
    },
  })

  const claimMutation = useMutation({
    mutationFn: claimTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
      setSuccessDialogOpen(false)
      setLoading(false)
      toast.success(
        `You have successfully claimed ${task.task.reward_point.toLocaleString('en-US')} $BECA`,
      )
    },
    onError: (error) => {
      toast.error(`Unable to claim reward: ${error.message}`)
      setSuccessDialogOpen(false)
      setLoading(false)
    },
  })

  console.log(111, swapHistory)

  const isCompletedTask = useMemo(() => {
    if (
      task.task.action_type === 'onchain_swap_token' &&
      task?.swap_token_from &&
      task?.swap_token_to
    ) {
      return checkSwapTokenToday(
        swapHistory,
        task?.swap_token_from,
        task?.swap_token_to,
        Number(task.task.minimum_requirement),
      )
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (
      task.task.action_type === 'onchain_add_liquidity' &&
      task?.swap_token_from &&
      task?.swap_token_to
    ) {
      return checkAddLiquidityToday(
        addLiquidityTransaction,
        task?.swap_token_from,
        task?.swap_token_to,
      )
    }
  }, [swapHistory, addLiquidityTransaction, task])

  // console.log({ isCompletedTask }, task.task.action_type)

  const closeDialog = () => {
    setOpen(false)
  }

  const handleChangeTask = () => {
    completeMutation.mutate(task.task.id)
  }

  const handleClaimTask = async () => {
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 2000))
    claimMutation.mutate(task.task.id)
  }

  const checkTask = () => {
    if (isCompletedTask) {
      handleChangeTask()
      setOpen(false)
      setSuccessDialogOpen(true)
    } else {
      setOpen(false)
      setErrorFaucetOpen(true)
    }
  }

  const InfoModal = () => {
    return (
      <BottomSheetModal
        open={open}
        title={'Task'}
        onClose={() => closeDialog()}
        heightPercent={0.6}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={
              task?.task?.image_url
                ? task.task.image_url
                : generateImageByTask(task)
            }
            alt="telegram icon"
            className="w-[64px] h-[64px] rounded-[50%]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${task.task.reward_point}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loading && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => checkTask()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Join & Finish task
              </p>
            </WalletButton>
          )}
          {loading && (
            <div className="w-full">
              <LoadingButton />
            </div>
          )}
        </div>
      </BottomSheetModal>
    )
  }

  const SuccessDialog = () => {
    return (
      <BottomSheetModal
        open={successDialogOpen}
        title={'Task'}
        onClose={() => setSuccessDialogOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="w-full h-full flex flex-col items-center justify-center">
            <img
              src={TransactionSuccess}
              alt="transaction success"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission successful
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              Tip: Claim BERA every day to get BERA + BECA. The more BERA you
              hold, the better your chances of getting an airdrop.
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            {!loading && (
              <WalletButton
                className="w-full h-[48px]"
                onPress={handleClaimTask}
              >
                <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                  Claim reward
                </p>
              </WalletButton>
            )}
            {loading && (
              <div className="w-full">
                <LoadingButton />
              </div>
            )}
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  const ErrorFaucet = () => {
    return (
      <BottomSheetModal
        open={errorFaucetOpen}
        title={'Task'}
        onClose={() => setErrorFaucetOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="flex flex-col  items-center justify-center">
            <img
              src={WarningIcon}
              alt="transaction error"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission Fail
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              You have not completed this task. Please experience Dapps first.
            </p>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => {
                setErrorFaucetOpen(false)
                navigate({
                  pathname: 'swap',
                  search: createSearchParams({
                    activeTab:
                      task.task.action_type === 'onchain_swap_token'
                        ? SwapTab.Swap.toString()
                        : SwapTab.AddLiquidity.toString(),
                  }).toString(),
                })
              }}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Experience it now!
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  return (
    <div>
      {InfoModal()}
      {SuccessDialog()}
      {ErrorFaucet()}
    </div>
  )
}
