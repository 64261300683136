import { useMutation } from '@tanstack/react-query'
import request from '../config/request'

async function updateMemeToken(id: number, status: string) {
  const formData = new FormData()
  formData.append(
    'deployed_status',
    status === 'success' ? 'deployed' : 'failed',
  )
  const res = await request.patch(`/meme-coin/${id}`, formData)

  return res.data
}

export const useUpdateMemeToken = () => {
  return useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: number
      status: string
    }) => updateMemeToken(id, status),
  })
}
