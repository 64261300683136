import TemplateMeme from '@/assets/Images/DAPPS/template-meme.png'
import Back from '@/assets/svg/back-icon.svg'
import Copy from '@/assets/svg/copy-blue.svg'
import telegramIcon from '@/assets/svg/telegram-blue.svg'
import websiteIcon from '@/assets/svg/website-blue.svg'
import xIcon from '@/assets/svg/x-blue.svg'
import { type MemeToken, Tabs } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useUtils } from '@telegram-apps/sdk-react'
import { format } from 'date-fns'
import { useMemo } from 'react'
// import { useState } from "react"
import { InfoAndChart } from './InfoAndChart'
import { TradePlace } from './TradePlace'

interface Props {
  tokenSelect: MemeToken
}

const DetailMeme = (props: Props) => {
  const { tokenSelect } = props

  // const [openChatBox, setOpenChatBox] = useState<boolean>(false)
  const handleChangeTab = (tab: Tabs) => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  const listSocial = [
    { image: websiteIcon, link: tokenSelect?.website_url },
    { image: xIcon, link: tokenSelect?.x_url },
    { image: telegramIcon, link: tokenSelect?.telegram_url },
    { image: Copy, link: '' },
  ]

  const detailMeme = useMemo(() => {
    let result = tokenSelect?.description || ''
    if (result.length > 100) {
      result = `${result.slice(0, 100)}...`
    }
    return result
  }, [tokenSelect])

  const utils = useUtils()

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-160px)] p-[10px] flex flex-col gap-[10px] pb-[20px]">
      <div
        className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
        onClick={() => handleChangeTab(Tabs.Meme)}
        onKeyDown={() => {}}
      >
        <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
        <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
          Back
        </p>
      </div>
      <div className="py-[12px] px-[16px] rounded-[16px] bg-[#52A7E71F] flex flex-col">
        <div className="flex gap-[20px] items-start justify-start">
          <img
            src={tokenSelect?.image_url ? tokenSelect.image_url : TemplateMeme}
            alt="coin"
            className="w-[64px] h-[64px] rounded-[12px]"
          />
          <div className="flex flex-col gap-[10px]">
            <p className="text-[#FFFFFF] text-[20px] font-bold">
              {tokenSelect?.name}
            </p>
            <p className="text-[#FFFFFFa0] text-[14px] font-[400] whitespace-break-spaces">
              {detailMeme}
            </p>
            <div className="flex flex-wrap gap-[20px]">
              {listSocial.map((element) => {
                return (
                  element?.link && (
                    <img
                      onClick={() => utils.openLink(element.link as string)}
                      onKeyDown={() => {}}
                      src={element.image}
                      alt="social icon"
                      className="w-[24px] h-[24px]"
                      key={element.image}
                    />
                  )
                )
              })}
            </div>
          </div>
        </div>
        <div className="border-t-[#1A5E90] border-t-[1px] border-solid p-[12px] pb-[0px] flex items-center justify-between mt-[10px]">
          <div className="text-[#ffffffa0] text-[12px] font-[400] flex flex-col">
            <p>Creator:</p>
            <p className="text-[#ffffff]">
              {formatWalletAddress(tokenSelect.owner_address)}
            </p>
          </div>
          <div className="w-[4px] h-[4px] bg-[#003F60] rounded-[50%]" />
          <div className="text-[#ffffffa0] text-[12px] font-[400] flex flex-col">
            <p>Created:</p>
            <p className="text-[#ffffff]">
              {format(tokenSelect.created_at, 'yyyy-MM-dd HH:mm:ss')}
            </p>
          </div>
        </div>
      </div>
      <TradePlace tokenSelect={tokenSelect} />
      <InfoAndChart tokenSelected={tokenSelect} />
    </div>
  )
}

export { DetailMeme }
