import { NATIVE_TOKEN_ADDRESS } from '../constant/addresses'
import { type Token, TokenType } from '../types'

export const HEADER_HEIGHT = 56
export const BOTTOM_BAR_HEIGHT = 77
export const TAB_HEIGHT = 48
export const WALLET_FEATURE_HEIGHT = 160
export const EMBLA_CAROUSEL_HEIGHT = 130
export const NFT_IDS = [1, 2, 3, 4, 5]
export const isDev = import.meta.env.DEV
export const isDevInsideTelegram =
  import.meta.env.VITE_DEV_INSIDE_TELEGRAM === 'true'
export const isUseTelegramApp = isDevInsideTelegram || !isDev
export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const METHOD_ID = {
  MULTI_SWAP: '0x0b2f6f3f',
  USER_CMD: '0xa15112f9',
}
export const listTokenSwap: Token[] = [
  {
    id: 1,
    contract_address: NATIVE_TOKEN_ADDRESS,
    name: 'BERA',
    symbol: 'BERA',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/bera.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: true,
    is_sendable: true,
  },
  {
    id: 2,
    contract_address: '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03',
    name: 'HONEY',
    symbol: 'HONEY',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/honey.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 3,
    contract_address: '0x7507c1dc16935b82698e4c63f2746a2fcf994df8',
    name: 'WBERA',
    symbol: 'WBERA',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/wbera.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 4,
    contract_address: '0xd6D83aF58a19Cd14eF3CF6fe848C9A4d21e5727c',
    name: 'STGUSDC',
    symbol: 'STGUSDC',
    decimals: 6,
    image_url:
      'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 5,
    contract_address: '0x2577D24a26f8FA19c1058a8b0106E2c7303454a4',
    name: 'WBTC',
    symbol: 'WBTC',
    decimals: 8,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/btc.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 6,
    contract_address: '0xE28AfD8c634946833e89ee3F122C06d7C537E8A8',
    name: 'WETH',
    symbol: 'WETH',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/eth.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
]

export const priceData = [
  {
    time: 1539907200,
    open: 180.34,
    high: 180.99,
    low: 178.57,
    close: 179.85,
  },
  {
    time: 1540166400,
    open: 180.82,
    high: 181.4,
    low: 177.56,
    close: 178.75,
  },
  {
    time: 1540252800,
    open: 175.77,
    high: 179.49,
    low: 175.44,
    close: 178.53,
  },
  {
    time: 1540339200,
    open: 178.58,
    high: 182.37,
    low: 176.31,
    close: 176.97,
  },
  {
    time: 1540425600,
    open: 177.52,
    high: 180.5,
    low: 176.83,
    close: 179.07,
  },
  {
    time: 1540512000,
    open: 176.88,
    high: 177.34,
    low: 170.91,
    close: 172.23,
  },
  {
    time: 1540771200,
    open: 173.74,
    high: 175.99,
    low: 170.95,
    close: 173.2,
  },
  {
    time: 1540857600,
    open: 173.16,
    high: 176.43,
    low: 172.64,
    close: 176.24,
  },
  {
    time: 1540944000,
    open: 177.98,
    high: 178.85,
    low: 175.59,
    close: 175.88,
  },
  {
    time: 1541030400,
    open: 176.84,
    high: 180.86,
    low: 175.9,
    close: 180.46,
  },
  {
    time: 1541116800,
    open: 182.47,
    high: 183.01,
    low: 177.39,
    close: 179.93,
  },
  {
    time: 1541376000,
    open: 181.02,
    high: 182.41,
    low: 179.3,
    close: 182.19,
  },
  {
    time: 1541462400,
    open: 181.93,
    high: 182.65,
    low: 180.05,
    close: 182.01,
  },
  {
    time: 1541548800,
    open: 183.79,
    high: 187.68,
    low: 182.06,
    close: 187.23,
  },
  {
    time: 1541635200,
    open: 187.13,
    high: 188.69,
    low: 185.72,
    close: 188.0,
  },
  {
    time: 1541721600,
    open: 188.32,
    high: 188.48,
    low: 184.96,
    close: 185.99,
  },
  {
    time: 1541980800,
    open: 185.23,
    high: 186.95,
    low: 179.02,
    close: 179.43,
  },
  {
    time: 1542067200,
    open: 177.3,
    high: 181.62,
    low: 172.85,
    close: 179.0,
  },
  {
    time: 1542153600,
    open: 182.61,
    high: 182.9,
    low: 179.15,
    close: 179.9,
  },
  {
    time: 1542240000,
    open: 179.01,
    high: 179.67,
    low: 173.61,
    close: 177.36,
  },
  {
    time: 1542326400,
    open: 173.99,
    high: 177.6,
    low: 173.51,
    close: 177.02,
  },
  {
    time: 1542585600,
    open: 176.71,
    high: 178.88,
    low: 172.3,
    close: 173.59,
  },
  {
    time: 1542672000,
    open: 169.25,
    high: 172.0,
    low: 167.0,
    close: 169.05,
  },
  {
    time: 1542758400,
    open: 170.0,
    high: 170.93,
    low: 169.15,
    close: 169.3,
  },
  {
    time: 1542931200,
    open: 169.39,
    high: 170.33,
    low: 168.47,
    close: 168.85,
  },
  {
    time: 1543190400,
    open: 170.2,
    high: 172.39,
    low: 168.87,
    close: 169.82,
  },
  {
    time: 1543276800,
    open: 169.11,
    high: 173.38,
    low: 168.82,
    close: 173.22,
  },
  {
    time: 1543363200,
    open: 172.91,
    high: 177.65,
    low: 170.62,
    close: 177.43,
  },
  {
    time: 1543449600,
    open: 176.8,
    high: 177.27,
    low: 174.92,
    close: 175.66,
  },
  {
    time: 1543536000,
    open: 175.75,
    high: 180.37,
    low: 175.11,
    close: 180.32,
  },
  {
    time: 1543795200,
    open: 183.29,
    high: 183.5,
    low: 179.35,
    close: 181.74,
  },
  {
    time: 1543881600,
    open: 181.06,
    high: 182.23,
    low: 174.55,
    close: 175.3,
  },
  {
    time: 1544054400,
    open: 173.5,
    high: 176.04,
    low: 170.46,
    close: 175.96,
  },
  {
    time: 1544140800,
    open: 175.35,
    high: 178.36,
    low: 172.24,
    close: 172.79,
  },
  {
    time: 1544400000,
    open: 173.39,
    high: 173.99,
    low: 167.73,
    close: 171.69,
  },
  {
    time: 1544486400,
    open: 174.3,
    high: 175.6,
    low: 171.24,
    close: 172.21,
  },
  {
    time: 1544572800,
    open: 173.75,
    high: 176.87,
    low: 172.81,
    close: 174.21,
  },
  {
    time: 1544659200,
    open: 174.31,
    high: 174.91,
    low: 172.07,
    close: 173.87,
  },
  {
    time: 1544745600,
    open: 172.98,
    high: 175.14,
    low: 171.95,
    close: 172.29,
  },
  {
    time: 1545004800,
    open: 171.51,
    high: 171.99,
    low: 166.93,
    close: 167.97,
  },
  {
    time: 1545091200,
    open: 168.9,
    high: 171.95,
    low: 168.5,
    close: 170.04,
  },
  {
    time: 1545177600,
    open: 170.92,
    high: 174.95,
    low: 166.77,
    close: 167.56,
  },
  {
    time: 1545264000,
    open: 166.28,
    high: 167.31,
    low: 162.23,
    close: 164.16,
  },
  {
    time: 1545350400,
    open: 162.81,
    high: 167.96,
    low: 160.17,
    close: 160.48,
  },
  {
    time: 1545609600,
    open: 160.16,
    high: 161.4,
    low: 158.09,
    close: 158.14,
  },
  {
    time: 1545782400,
    open: 159.46,
    high: 168.28,
    low: 159.44,
    close: 168.28,
  },
  {
    time: 1545868800,
    open: 166.44,
    high: 170.46,
    low: 163.36,
    close: 170.32,
  },
  {
    time: 1545955200,
    open: 171.22,
    high: 173.12,
    low: 168.6,
    close: 170.22,
  },
  {
    time: 1546214400,
    open: 171.47,
    high: 173.24,
    low: 170.65,
    close: 171.82,
  },
  {
    time: 1546387200,
    open: 169.71,
    high: 173.18,
    low: 169.05,
    close: 172.41,
  },
  {
    time: 1546473600,
    open: 171.84,
    high: 171.84,
    low: 168.21,
    close: 168.61,
  },
  {
    time: 1546560000,
    open: 170.18,
    high: 174.74,
    low: 169.52,
    close: 173.62,
  },
  {
    time: 1546819200,
    open: 173.83,
    high: 178.18,
    low: 173.83,
    close: 177.04,
  },
  {
    time: 1546905600,
    open: 178.57,
    high: 179.59,
    low: 175.61,
    close: 177.89,
  },
  {
    time: 1546992000,
    open: 177.87,
    high: 181.27,
    low: 177.1,
    close: 179.73,
  },
  {
    time: 1547078400,
    open: 178.03,
    high: 179.24,
    low: 176.34,
    close: 179.06,
  },
  {
    time: 1547164800,
    open: 177.93,
    high: 180.26,
    low: 177.12,
    close: 179.41,
  },
  {
    time: 1547424000,
    open: 177.59,
    high: 179.23,
    low: 176.9,
    close: 178.81,
  },
  {
    time: 1547510400,
    open: 176.08,
    high: 177.82,
    low: 175.2,
    close: 176.47,
  },
  {
    time: 1547596800,
    open: 177.09,
    high: 177.93,
    low: 175.86,
    close: 177.04,
  },
  {
    time: 1547683200,
    open: 174.01,
    high: 175.46,
    low: 172.0,
    close: 174.87,
  },
  {
    time: 1547769600,
    open: 176.98,
    high: 180.04,
    low: 176.18,
    close: 179.58,
  },
  {
    time: 1548115200,
    open: 177.49,
    high: 178.6,
    low: 175.36,
    close: 177.11,
  },
  {
    time: 1548201600,
    open: 176.59,
    high: 178.06,
    low: 174.53,
    close: 176.89,
  },
  {
    time: 1548288000,
    open: 177.0,
    high: 177.53,
    low: 175.3,
    close: 177.29,
  },
  {
    time: 1548374400,
    open: 179.78,
    high: 180.87,
    low: 178.61,
    close: 180.4,
  },
  {
    time: 1548633600,
    open: 178.97,
    high: 179.99,
    low: 177.41,
    close: 179.83,
  },
  {
    time: 1548720000,
    open: 178.96,
    high: 180.15,
    low: 178.09,
    close: 179.69,
  },
  {
    time: 1548806400,
    open: 180.47,
    high: 184.2,
    low: 179.78,
    close: 182.18,
  },
  {
    time: 1548892800,
    open: 181.5,
    high: 184.67,
    low: 181.06,
    close: 183.53,
  },
  {
    time: 1548979200,
    open: 184.03,
    high: 185.15,
    low: 182.83,
    close: 184.37,
  },
  {
    time: 1549238400,
    open: 184.3,
    high: 186.43,
    low: 183.84,
    close: 186.43,
  },
  {
    time: 1549324800,
    open: 186.89,
    high: 186.99,
    low: 184.69,
    close: 186.39,
  },
  {
    time: 1549411200,
    open: 186.69,
    high: 186.69,
    low: 184.06,
    close: 184.72,
  },
  {
    time: 1549497600,
    open: 183.74,
    high: 184.92,
    low: 182.45,
    close: 184.07,
  },
  {
    time: 1549584000,
    open: 183.05,
    high: 184.58,
    low: 182.72,
    close: 184.54,
  },
  {
    time: 1549843200,
    open: 185.0,
    high: 185.42,
    low: 182.75,
    close: 182.92,
  },
]
