import { useViewport } from '@telegram-apps/sdk-react'
import type { PropsWithChildren } from 'react'
import { calculateListHeight } from '../utils'

const TokenAssetsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const vp = useViewport()
  const height = calculateListHeight(vp?.height)
  return (
    <div
      className="flex flex-col gap-4 pt-4 pb-2 overflow-y-auto rounded-lg"
      style={{ height }}
    >
      {children}
    </div>
  )
}

export { TokenAssetsContainer }
