import DefaultUpload from '@/assets/Images/DAPPS/upload-image.png'
import Add from '@/assets/svg/add-blue.svg'
import Back from '@/assets/svg/back-icon.svg'
import Minus from '@/assets/svg/minus.svg'
import { WalletButton } from '@/components/Button'
import { useMutationCreateMemeToken, useUpdateMemeToken } from '@/libs/hooks'
import { Tabs } from '@/libs/types'
import { memeForm } from '@/libs/utils/createMemeForm'
import { createMemeTokenOnChain, publicClient } from '@/libs/utils/wallet/meme'
import { bottomTabStore } from '@/store/BottomTabStore'
import { walletStore } from '@/store/WalletStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import type { AxiosError } from 'axios'
import { useRef, useState } from 'react'
import { TextArea } from 'react-aria-components'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { privateKeyToAccount } from 'viem/accounts'
import { useWriteContract } from 'wagmi'
import {
  type TCreateMemeSchema,
  createMemeSchema,
} from './schema/CreateMemeSchema'

const CreateMeme = () => {
  const queryClient = useQueryClient()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)

  const {
    control,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<TCreateMemeSchema>({
    resolver: zodResolver(createMemeSchema),
    defaultValues: {
      name: '',
      symbol: '',
      description: '',
      website: '',
      x_url: '',
      telegram_url: '',
      owner_address: activeAccount?.address as string,
    },
  })

  const [openSocial, setOpenSocial] = useState<boolean>(false)
  const [checkTemp, setCheckTemp] = useState<boolean>(false)
  const [showImage, setShowImage] = useState(DefaultUpload)
  const [avatarFile, setAvatarFile] = useState<FileList | null>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { mutateAsync: createMemeToken } = useMutationCreateMemeToken()
  const { mutate: updateMemeToken } = useUpdateMemeToken()

  const { writeContractAsync } = useWriteContract()

  const uploadRef = useRef<HTMLInputElement>(null)

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files
    console.log({ files })
    let url = ''
    if (files) {
      url = URL.createObjectURL(files[0])
      setAvatarFile(null)
    }
    setAvatarFile(files)
    setShowImage(url)
  }

  const chooseImage = () => {
    if (uploadRef.current) {
      uploadRef.current.click()
    }
  }

  const disableButton =
    Object.keys(errors).length > 0 || isLoading || !checkTemp || !avatarFile
  console.log({ errors })

  const handleChangeTab = (tab: Tabs) => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  const createTokenOnchain = async (
    data: TCreateMemeSchema,
    tokenId: number,
  ) => {
    try {
      const request = await createMemeTokenOnChain({
        symbol: data.symbol,
        name: data.name,
      })

      const hash = await writeContractAsync({ ...request, account: signer })
      console.log({ hash })
      const receipt = await publicClient.waitForTransactionReceipt({
        hash,
        confirmations: 1,
      })

      if (receipt) {
        updateMemeToken({
          id: tokenId,
          status: receipt.status,
        })
        queryClient.invalidateQueries({ queryKey: ['meme-tokens'] })
      }
      toast.success('Create token successfully')
      setIsLoading(false)
      handleChangeTab(Tabs.Meme)
    } catch (error) {
      toast.error("Create token failed, may be you don't have enough BERA")
      setIsLoading(false)
    }
  }

  const onSubmit = async (data: TCreateMemeSchema) => {
    setIsLoading(true)
    await createMemeToken(
      {
        ...data,
        image: avatarFile?.[0] as File,
      },

      {
        onSuccess: (tokenId) => createTokenOnchain(data, tokenId),
        onError: (err: Error) => {
          const axiosError = err as AxiosError<{
            errors?: Record<string, string>
          }>

          const errors = axiosError.response?.data?.errors as unknown as {
            path: string[]
            message: string
          }[]

          // map array errors
          if (errors) {
            for (const error of errors) {
              setError(error.path[0] as keyof TCreateMemeSchema, {
                message: error.message,
              })
            }
          }
          setIsLoading(false)
        },
      },
    )
  }

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-160px)] p-[10px] flex flex-col gap-[10px] pb-[20px]">
      <div
        className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
        onClick={() => handleChangeTab(Tabs.Meme)}
        onKeyDown={() => {}}
      >
        <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
        <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
          Back
        </p>
      </div>
      <p className="text-[#ffffff] text-[20px] font-bold">
        Create your own coin
      </p>
      {memeForm.map((element) => {
        return (
          element.show && (
            <div
              key={element.label}
              className="flex flex-col items-start gap-[5px] mt-[10px]"
            >
              <p className="text-[12px] text-[#ffffff] font-bold">
                {element.label}
              </p>
              <Controller
                control={control}
                name={element.field}
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    className="w-full bg-transparent border-[1px] border-blue-200 focus:border-yellow px-4 py-3 rounded-[8px] text-white placeholder-white placeholder-opacity-64 text-sm"
                    placeholder={element.placeholder}
                    rows={element.row}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors[element.field]?.message && (
                <p className="text-[#ff6347] text-[14px]">
                  {errors?.[element.field]?.message}
                </p>
              )}
            </div>
          )
        )
      })}
      <div className="border-solid border-[1px] border-[#3892CF] rounded-[12px] bg-[#52A7E73D] p-[10px] flex items-center justify-between mt-[10px]">
        <div className="flex items-center justify-center">
          <input
            ref={uploadRef}
            type="file"
            className="w-[0px] h-[0px] hidden"
            accept="image/*"
            onChange={handleFileSelected}
          />
          <img
            src={showImage}
            alt="token-avatar"
            className="w-[64px] h-[64px] rounded-[12px]"
          />
          <div className="ml-[10px]">
            <p className="text-[#ffffff] text-[14px] font-bold">Token Avatar</p>
            <p className="text-[#ffffffa0] text-[14px] font-[400]">
              (.png, .jpg, .gif)
            </p>
          </div>
        </div>
        <WalletButton
          className="max-h-[32px] max-w-[80px]"
          onPress={() => chooseImage()}
          id="selectFileButton"
        >
          <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
            Upload
          </p>
        </WalletButton>
      </div>
      <div
        className="w-full rounded-[14px] min-h-[48px] flex items-center justify-start gap-[10px]"
        onClick={() => setOpenSocial(!openSocial)}
        onKeyDown={() => {}}
      >
        <img
          src={openSocial ? Minus : Add}
          alt="social open"
          className="w-[24px] h-[24px]"
        />
        <p className="text-[#71C2FF] text-[16px] font-[700] flex items-center justify-center gap-[5px] h-full">
          Show option
        </p>
      </div>
      {memeForm.map((element) => {
        return (
          !element.show &&
          openSocial && (
            <div
              key={element.label}
              className="flex flex-col items-start gap-[5px] mt-[10px]"
            >
              <p className="text-[12px] text-[#ffffff] font-bold">
                {element.label}
              </p>
              <Controller
                control={control}
                name={element.field}
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    className="w-full bg-transparent border-[1px] border-blue-200 focus:border-yellow px-4 py-3 rounded-[8px] text-white placeholder-white placeholder-opacity-64 text-sm"
                    placeholder={element.placeholder}
                    rows={element.row}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors[element.field]?.message && (
                <p className="text-[#ff6347] text-[14px]">
                  {errors?.[element.field]?.message}
                </p>
              )}
            </div>
          )
        )
      })}
      <div className="flex gap-[10px] mt-[10px]">
        <input
          className="rounded-[50%] w-[20px] h-[20px]"
          type="checkbox"
          checked={checkTemp}
          onChange={(e) => setCheckTemp(e.target.checked)}
        />
        <p className="text-[#ffffff] text-[14px] font-[400]">
          I agree to the Beca Wallet{' '}
          <span className="text-[#FAC808] font-bold border-solid border-b-[1px] border-[#FAC808]">
            Terms of Use
          </span>
        </p>
      </div>
      <button
        type="button"
        disabled={disableButton}
        onClick={handleSubmit(onSubmit)}
        className={`${disableButton === false ? 'bg-[#8C6F00] border-[#FAC808]' : 'bg-[#8C6F001a] border-[#FAC8081a]'} border-[1.5px] border-solid rounded-[16px] w-full min-h-[48px] flex items-center justify-center my-[20px]`}
      >
        <p
          className={`${disableButton === false ? 'text-[#FFFFFF]' : 'text-[#FFFFFF80]'} text-[16px] font-[700] flex items-center justify-center gap-[10px]`}
        >
          Create Coin
          {isLoading && (
            <svg
              aria-hidden="true"
              className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          )}
        </p>
      </button>
      <p className="text-[#ffffffa0] text-[14px] font-[400]">
        When your coin completes its bonding curve you receive 0.5 BERA
      </p>
    </div>
  )
}

export { CreateMeme }
