import CBTRouterAbi from '@/libs/evm/abi/CBTRouter.json'
import {
  http,
  type PrivateKeyAccount,
  createPublicClient,
  formatEther,
  parseEther,
} from 'viem'
import { berachainTestnetbArtio, mainnet } from 'viem/chains'
import { contractAddressList } from '../contractAddress'

export const publicClient = createPublicClient({
  chain:
    import.meta.env.VITE_NODE_ENV === 'production'
      ? mainnet
      : berachainTestnetbArtio,
  transport: http(),
})

export async function getCreationFee() {
  const fee = (await publicClient.readContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'tokenCreationFee',
  })) as bigint
  return formatEther(fee)
}

export async function getMemePredictedTokenAddress({
  ownerAddress,
  symbol,
  name,
}: {
  ownerAddress: string
  symbol: string
  name: string
}) {
  const predictedTokenAddress = await publicClient.readContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'getPredictedTokenAddress',
    args: [ownerAddress, name, symbol],
  })

  return predictedTokenAddress
}

export async function createMemeTokenOnChain({
  symbol,
  name,
}: {
  symbol: string
  name: string
}) {
  const tokenCreationFee = await getCreationFee()
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'createCBT',
    args: [name, symbol],
    value: parseEther(tokenCreationFee),
  })
  return request
}

export async function calculateCurvedMintReturn({
  buyAmount,
  tokenAddress,
}: {
  buyAmount: number
  tokenAddress: string
}) {
  const result = (await publicClient.readContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'calculateCurvedMintReturn',
    args: [tokenAddress, parseEther(buyAmount.toString())],
  })) as bigint
  const formatResult = formatEther(result)
  return formatResult
}

export async function calculateCurvedBurnReturn({
  burnAmount,
  tokenAddress,
}: {
  burnAmount: number
  tokenAddress: string
}) {
  const result = (await publicClient.readContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'calculateCurvedBurnReturn',
    args: [tokenAddress, parseEther(burnAmount.toString())],
  })) as bigint

  const formatResult = formatEther(result)
  return formatResult
}

export async function buyMemeToken({
  tokenAddress,
  buyAmount,
  signer,
}: {
  tokenAddress: string
  buyAmount: number
  signer: PrivateKeyAccount
}) {
  const curvedMintAmount = await calculateCurvedMintReturn({
    buyAmount,
    tokenAddress,
  })
  const formatCurvedMinAmount = parseEther(curvedMintAmount.toString())
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'mint',
    args: [tokenAddress, formatCurvedMinAmount],
    value: parseEther(buyAmount.toString()),
    account: signer,
  })
  return request
}

export async function sellMemeToken({
  tokenAddress,
  sellAmount,
  signer,
}: {
  tokenAddress: string
  sellAmount: number
  signer: PrivateKeyAccount
}) {
  const minAmount = await calculateCurvedBurnReturn({
    burnAmount: sellAmount,
    tokenAddress,
  })
  const burnAmount = parseEther(sellAmount.toString())
  const formatMinAmount = parseEther(minAmount.toString())
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.CBTRouter,
    abi: CBTRouterAbi,
    functionName: 'burn',
    args: [tokenAddress, burnAmount, formatMinAmount],
    account: signer,
  })
  return request
}
